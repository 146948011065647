<template>
    <div class="nav nav-tabs nav-fill mb-3">
        <div v-bind:class="['nav-link', step === 1 ? 'active' : 'disabled']">ШАГ 1 Наполнение меню</div>
        <div v-bind:class="['nav-link', step === 2 ? 'active' : 'disabled']">ШАГ 2 Выбор формата-носителя</div>
        <div v-bind:class="['nav-link', step === 3 ? 'active' : 'disabled']">ШАГ 3 Выбор внутреннего дизайна</div>
        <div v-bind:class="['nav-link', step === 4 ? 'active' : 'disabled']">ШАГ 4 Выбор стиля и цвета обложки</div>
    </div>

    <template v-if="step === 1">
        <div class="d-flex mb-3">
            <div class="flex-fill">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Введите название позиции"
                    style="max-width: 18rem; width: 100%"
                    v-model.trim="search"
                />
            </div>
            <div class="">
                <div class="d-flex align-items-center">
                    <div class="fw-bold d-none d-sm-block">Действия</div>
                    <div class="ms-2">
                        <div class="dropdown">
                            <button type="button" class="btn btn-outline-primary" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" v-on:click="this.$refs.saveDraftModal.show()">Сохранить черновик</a></li>
                                <li><a class="dropdown-item" href="#" v-on:click="this.$refs.composeProductModal.show()">Добавить позицию</a></li>
                                <li><a class="dropdown-item" href="#" v-on:click="this.$refs.cleanProductsModal.show()">Очистить меню</a></li>
                                <template v-if="menuId > 0">
                                    <li><hr class="dropdown-divider" /></li>
                                    <li><a class="dropdown-item" href="#" v-on:click="this.$refs.deleteMenuModal.show()">Удалить меню</a></li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-xl-2 mb-3">
            <div class="col d-flex flex-column" style="height: 35rem">
                <div class="nav nav-tabs mb-3">
                    <button
                        type="button"
                        v-for="[id, name] in Object.entries(categories)"
                        v-bind:key="id"
                        v-bind:class="{
                            'nav-link': true,
                            'active': id === category
                        }"
                        v-on:click="category = id"
                    >{{name}}</button>
                </div>

                <div class="flex-fill" style="overflow-x: hidden; overflow-y: auto">
                    <div class="row row-cols-1 row-cols-sm-2 g-2">
                        <div class="col" v-for="product in getProducts(parseInt(category))" v-bind:key="product.id">
                            <div class="d-flex bg-light rounded px-3 py-2">
                                <div class="flex-fill fw-semibold me-2">{{product.name}}</div>
                                <div class="fw-light me-2">{{product.volume}} мл</div>
                                <div><a href="javascript:" v-on:click="this.$refs.composeProductModal.show(product)"><i class="bi bi-plus-square"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col" style="height: 35rem">
                <div class="w-100 h-100 overflow-auto">
                    <template v-if="Object.values(selected).find((position) => position.length > 0)">
                        <template v-for="[categoryId, positions] of Object.entries(selected)" v-bind:key="categoryId">
                            <div v-if="positions.length > 0">
                                <div class="text-primary p-2">{{categories[categoryId]}}</div>
                                <div class="bg-light rounded p-3 mb-2" v-for="(position, index) in positions" v-bind:key="index">
                                    <div class="d-flex">
                                        <div class="fw-semibold me-2"><i class="bi bi-list"></i> {{position.name}}</div>
                                        <div class="flex-fill me-2">{{position.volume}} мл</div>
                                        <div class="me-2">{{position.price}} руб</div>
                                        <div class="">
                                            <a href="#" class="mx-1" v-on:click="this.$refs.composeProductModal.show(position, index)"><i class="bi bi-pencil-fill"></i></a>
                                            <a href="#" class="mx-1" v-on:click="this.$refs.deleteProductModal.show(position, index)"><i class="bi bi-trash3-fill"></i></a>
                                        </div>
                                    </div>
                                    <div class="text-secondary mt-1" v-if="position.description.length > 0">{{position.description}}</div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="bg-light rounded text-secondary p-3">
                            Начните добавлять позиции из списка слева, или добавьте свою позицию из меню Действий.
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="text-end">
            <button type="button" class="btn btn-primary mx-1" v-on:click="$router.push('/')"><i class="bi bi-arrow-left"></i> Назад</button>
            <button type="button" class="btn btn-primary mx-1" v-on:click="step++" v-bind:disabled="menuSize < minSize || menuSize > maxSize">Далее <i class="bi bi-arrow-right"></i></button>
        </div>
    </template>
    <template v-else-if="step === 2">
        <div class="row g-3 mb-3">
            <div class="col-12 col-lg-6" style="height: 40rem">
                <div id="formatRadios" class="row row-cols-1 row-cols-sm-2 g-3">
                    <div class="col" v-for="format in formats" v-bind:key="format.id">
                        <input
                            type="radio"
                            class="d-none"
                            v-bind:id="`format` + format.id"
                            v-bind:value="format.id"
                            v-bind:disabled="menuSize < format.minimum || menuSize > format.maximum"
                            v-model.number="formatId"
                            v-on:change="updatePreview()"
                        />
                        <label v-bind:for="`format` + format.id" class="d-flex align-items-center rounded p-3 h-100">
                            <img class="d-block me-2 me-md-3 me-lg-4" v-bind:src="require(`@/assets/images/formats/${format.id}.png`)" alt="" />
                            <div class="lh-lg">
                                <div class="fw-bold">{{format.name}}</div>
                                <div style="font-size: 0.75rem">
                                    размер: {{format.width}} x {{format.height}}<br/>
                                    позиций: {{format.minimum}}-{{format.maximum}}
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column col-12 col-lg-6" style="height: 40rem">
                <div class="d-flex align-items-center mb-3">
                    <div class="fw-semibold ms-auto me-2">Действия</div>
                    <div class="dropdown">
                        <button type="button" class="btn btn-outline-primary" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" v-on:click="this.$refs.saveDraftModal.show()">Сохранить черновик</a></li>
                            <li><a class="dropdown-item" href="#">Скачать шаблон</a></li>
                            <template v-if="menuId > 0">
                                <li><hr class="dropdown-divider" /></li>
                                <li><a class="dropdown-item" href="#" v-on:click="this.$refs.deleteMenuModal.show()">Удалить меню</a></li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div class="flex-fill bg-light rounded p-3">
                    <iframe id="preview" v-bind:srcdoc="htmlContent" v-if="formatId"></iframe>
                    <div class="text-secondary text-center" v-else>Выберите любой формат...</div>
                </div>
            </div>
        </div>

        <div class="text-end">
            <button type="button" class="btn btn-primary mx-1" v-on:click="step--"><i class="bi bi-arrow-left"></i> Назад</button>
            <button type="button" class="btn btn-primary mx-1" v-on:click="step++; page = 2" v-bind:disabled="!formatId">Далее <i class="bi bi-arrow-right"></i></button>
        </div>
    </template>
    <template v-else-if="step === 3">
        <div class="d-flex align-items-center mb-3">
            <div class="fw-semibold ms-auto me-2">Действия</div>
            <div class="dropdown">
                <button type="button" class="btn btn-outline-primary" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" v-on:click="this.$refs.saveDraftModal.show()">Сохранить черновик</a></li>
                    <li><a class="dropdown-item" href="#">Скачать шаблон</a></li>
                    <template v-if="menuId > 0">
                        <li><hr class="dropdown-divider" /></li>
                        <li><a class="dropdown-item" href="#" v-on:click="this.$refs.deleteMenuModal.show()">Удалить меню</a></li>
                    </template>
                </ul>
            </div>
        </div>

        <div class="row g-3 mb-4">
            <div class="col-12 col-lg-6" style="height: 38rem">
                <template v-if="![6, 7].includes(parseInt(formatId))">
                    <h6 class="fw-bold">Графические элементы</h6>
                    <div id="cornerRadios" class="row row-cols-5 g-2 mb-2">
                        <div v-for="i in 3" v-bind:key="i">
                            <input v-bind:id="`corner` + i" class="d-none" type="radio" v-bind:value="i" v-model.number="cornerId" v-on:change="updatePreview()" />
                            <label v-bind:for="`corner` + i" class="rounded p-1">
                                <img class="d-block w-100" v-bind:src="require(`@/assets/images/corners/preview/${i}.png`)" alt="" />
                            </label>
                        </div>
                    </div>
                </template>

                <h6 class="fw-bold">Фотографии <span class="text-secondary">(не более {{currentFormat.images}})</span></h6>
                <div class=" bg-light text-muted text-center rounded p-3" v-if="currentFormat.maximum - menuSize < currentFormat.min_image_size + 2">
                    <div class="h4">Для вставки фото недостаточно места.</div>
                    <div class="">Вы можете убрать несколько позиций, либо продолжить составление меню без вставки фото.</div>
                </div>
                <div id="imagesChecks" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-2 mb-2" v-else>
                    <div v-for="i in 13" v-bind:key="i">
                        <input
                            class="d-none"
                            type="checkbox"
                            v-bind:id="`image` + i"
                            v-bind:checked="images.includes(i)"
                            v-on:click="onCheckImage(i, $event.target.checked)"
                            v-on:change="updatePreview"
                        />
                        <label v-bind:for="`image` + i" class="rounded p-1">
                            <img v-bind:class="{
                                'w-100': true,
                                'd-block': true,
                                'opacity-25': !images.includes(i) && images.length >= currentFormat.images
                            }" v-bind:src="require(`@/assets/images/photos/${i}.jpg`)" alt="" />
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6" style="height: 38rem">
                <div class="bg-light rounded p-3 h-100">
                    <div class="d-flex align-items-center h-100" v-if="formatId">
                        <iframe id="preview" v-bind:srcdoc="htmlContent"></iframe>
                        <div class="ms-3">
                            <button class="btn btn-lg btn-primary" v-on:click="page = page === 1 ? 2 : 1"><i class="bi bi-arrow-return-left"></i></button>
                        </div>
                    </div>
                    <div class="text-secondary text-center" v-else>Выберите любой формат...</div>
                </div>
            </div>
        </div>

        <div class="text-end">
            <button type="button" class="btn btn-primary mx-1" v-on:click="step--"><i class="bi bi-arrow-left"></i> Назад</button>
            <button type="button" class="btn btn-primary mx-1" v-on:click="step++; page = 1" v-bind:disabled="!formatId">Далее <i class="bi bi-arrow-right"></i></button>
        </div>
    </template>
    <template v-else-if="step === 4">
        <div class="d-flex align-items-center mb-3">
            <div class="fw-semibold ms-auto me-2">Действия</div>
            <div class="dropdown">
                <button type="button" class="btn btn-outline-primary" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" v-on:click="this.$refs.saveMenuModal.show()">Сохранить меню</a></li>
                    <li><a class="dropdown-item" href="#" v-on:click="this.downloadMenu()">Скачать меню</a></li>
                    <li><a class="dropdown-item" href="#" v-on:click="this.$refs.saveDraftModal.show()">Сохранить черновик</a></li>
                    <template v-if="menuId > 0">
                        <li><hr class="dropdown-divider" /></li>
                        <li><a class="dropdown-item" href="#" v-on:click="this.$refs.deleteMenuModal.show()">Удалить меню</a></li>
                    </template>
                </ul>
            </div>
        </div>

        <div class="row g-3 mb-4">
            <div class="col-12 col-lg-6">
                <h6 class="fw-bold">Заголовок меню</h6>
                <div class="mb-2">
                    <select class="form-select" v-model="title" v-on:change="updatePreview">
                        <option value="Кофе">Кофе</option>
                        <option value="Чай">Чай</option>
                        <option value="Меню">Меню</option>
                        <option value="Чайная карта">Чайная карта</option>
                        <option value="Кофейная карта">Кофейная карта</option>
                    </select>
                </div>

                <h6 class="fw-bold">Обложка меню</h6>
                <div id="coverRadios" class="row row-cols-4 g-2 mb-2">
                    <div v-for="i in 12" v-bind:key="i">
                        <input v-bind:id="`cover` + i" class="d-none" type="radio" v-bind:value="i" v-model.number="coverId" v-on:change="updatePreview()" />
                        <label v-bind:for="`cover` + i" class="rounded p-1">
                            <img class="d-block w-100" v-bind:src="require(`@/assets/images/covers/${i}.png`)" alt="" />
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6" style="height: 38rem">
                <div class="bg-light rounded p-3 h-100">
                    <div class="d-flex align-items-center h-100" v-if="formatId">
                        <iframe id="preview" v-bind:srcdoc="htmlContent"></iframe>
                        <div class="ms-3">
                            <button class="btn btn-lg btn-primary" v-on:click="page = page === 1 ? 2 : 1"><i class="bi bi-arrow-return-left"></i></button>
                        </div>
                    </div>
                    <div class="text-secondary text-center" v-else>Выберите любой формат...</div>
                </div>
            </div>
        </div>

        <div class="text-end">
            <button type="button" class="btn btn-primary mx-1" v-on:click="step--; page = 2;"><i class="bi bi-arrow-left"></i> Назад</button>
            <button type="button" class="btn btn-primary mx-1" v-on:click="$refs.saveMenuModal.show()"><i class="bi bi-check-circle"></i> Сохранить меню</button>
        </div>
    </template>

    <compose-product-modal
        ref="composeProductModal"
        v-on:submit="onComposeProduct"
        v-bind:categories="categories"
    />

    <delete-product-modal
        ref="deleteProductModal"
        v-on:submit="onDeleteProduct"
    />

    <clean-products-modal
        ref="cleanProductsModal"
        v-on:submit="onCleanProducts"
    />

    <save-draft-modal
        ref="saveDraftModal"
        v-on:submit="onSaveMenu($event, true)"
    />

    <save-menu-modal
        ref="saveMenuModal"
        v-on:submit="onSaveMenu($event, false)"
    />

    <delete-menu-modal
        ref="deleteMenuModal"
        v-on:submit="onDeleteMenu"
    />
</template>

<script>
import {useRoute} from 'vue-router';
import api from "@/api";
import ComposeProductModal from "@/components/modals/ComposeProductModal";
import DeleteProductModal from "@/components/modals/DeleteProductModal";
import CleanProductsModal from "@/components/modals/CleanProductsModal";
import SaveDraftModal from "@/components/modals/SaveDraftModal";
import DeleteMenuModal from "@/components/modals/DeleteMenuModal";
import SaveMenuModal from "@/components/modals/SaveMenuModal";

export default {
    name: "BuildView",
    components: {
        SaveMenuModal,
        DeleteMenuModal,
        SaveDraftModal,
        CleanProductsModal,
        DeleteProductModal,
        ComposeProductModal
    },
    data() {
        return {
            step: 1,
            menuId: 0,
            menuName: '',
            formatId: null,
            htmlContent: '',
            categories: {},
            category: '',
            products: [],
            formats: [],
            selected: {},
            search: '',
            cornerId: 1,
            coverId: 1,
            page: 1,
            images: [],
            title: '',
            titleTimer: null
        }
    },
    methods: {
        async fetchCategories() {
            const response = await api.categories.browse();
            this.categories = response.data;
            this.selected = Object.keys(this.categories).reduce((prev, current) => {prev[current] = []; return prev}, {});
            this.category = Object.keys(response.data)[0];
        },
        async fetchProducts() {
            const response = await api.products.browse(1, 9999);
            this.products = response.data.rows;
        },
        async fetchFormats() {
            const response = await api.formats.browse();
            this.formats = response.data;
        },
        async loadMenu(id) {
            const response = await api.menu.load(id);
            const menu = response.data;

            this.menuId = menu.id;
            this.menuName = menu.name;
            this.coverId = menu.cover || 1;
            this.cornerId = menu.corner || 1;
            this.formatId = menu.format_id || null;
            this.images = menu.images || [];
            this.title = menu.title || 'Заголовок';
            this.$refs.saveDraftModal.name = this.menuName;

            for (const i in this.selected) {
                this.selected[i] = [];
            }

            for (const product of menu.products) {
                this.selected[product.category_id].push(product);
            }
        },
        getProducts(id) {
            return this.products.filter((product) => {
                if (product.category_id !== id) return false;
                if (this.search && !product.name.toLowerCase().includes(this.search.toLowerCase())) return false;

                return true;
            });
        },
        getFormatById(id) {
            return this.formats.find((format) => format.id === id);
        },
        onComposeProduct(product) {
            const index = product.index;
            const categoryId = product.category_id;
            delete product.index;

            if (index === -1) {
                this.selected[categoryId].push(product);
            } else {
                this.selected[categoryId][index] = product;
            }

            this.updatePreview();
        },
        onDeleteProduct(product) {
            const index = product.index;
            const categoryId = product.category_id;
            this.selected[categoryId].splice(index, 1);
            this.updatePreview();
        },
        onCleanProducts() {
            for (const i in this.selected) this.selected[i] = [];
            this.updatePreview();
        },
        onDeleteMenu() {
            api.menu.delete(this.menuId).then(() => this.$router.push('/'));
        },
        onCheckImage(i, checked) {
            if (checked) {
                this.images.unshift(i);
                this.images = this.images.slice(0, this.currentFormat.images);
            } else {
                this.images = this.images.filter((image) => image !== i);
            }
        },
        getEncodedMenu() {
            const encoded = new URLSearchParams();

            for (const [categoryId, positions] of Object.entries(this.selected)) {
                let index = 0;
                for (const position of positions) {
                    encoded.set(`menu[${categoryId}][${index}][name]`, position.name);
                    encoded.set(`menu[${categoryId}][${index}][description]`, position.description);
                    encoded.set(`menu[${categoryId}][${index}][volume]`, position.volume);
                    encoded.set(`menu[${categoryId}][${index}][price]`, position.price);
                    index++;
                }
            }

            return encoded;
        },
        async downloadMenu() {
            const file = await api.menu.buildByData(this.title, this.formatId, this.coverId, this.cornerId, this.images, this.getEncodedMenu());
            const anchor = document.createElement('a');
            const url = URL.createObjectURL(file);

            anchor.href = url;
            anchor.download = `Menu ${this.menuId}.pdf`;
            anchor.click();

            URL.revokeObjectURL(url)
        },
        async onSaveMenu(name, isDraft = false) {
            await api.menu.save(this.menuId, isDraft, name, this.title, this.formatId, this.coverId, this.cornerId, this.images, this.getEncodedMenu());
            if (isDraft === false) this.$router.push('/');
        },
        async updatePreview() {
            if (this.formatId && this.cornerId && this.coverId) {
                await api.menu.preview(this.formatId, this.title, this.cornerId, this.coverId, this.images, this.page, this.selected)
                    .then((response) => this.htmlContent = response)
                    .catch((error) => this.htmlContent = error.toString());
            } else {
                this.htmlContent = '';
            }
        }
    },
    computed: {
        menuSize() {
            return Object.values(this.selected).reduce(
                (prev, current) => prev + current.reduce(
                    (prev2, current2) => prev2 + (current2.description ? 2 : 1), current.length > 0 ? 2 : 0
                ), 0);
        },
        minSize() {
            return this.formats.reduce((prev, current) => Math.min(prev, current.minimum), Infinity);
        },
        maxSize() {
            return this.formats.reduce((prev, current) => Math.max(prev, current.maximum), 0);
        },
        currentFormat() {
            return this.getFormatById(this.formatId);
        }
    },
    watch: {
        page() {
            this.updatePreview();
        }
    },
    mounted() {
        const route = useRoute();

        (async() => {
            await this.fetchCategories();
            await this.fetchProducts();
            await this.fetchFormats();

            this.menuId = route.params.id || 0;

            if (this.menuId > 0) await this.loadMenu(this.menuId);

            await this.updatePreview();
        })();
    }
}
</script>

<style scoped>
#formatRadios input + label {
    min-height: 9rem;
    cursor: pointer;
}

#formatRadios input:disabled + label {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

#formatRadios input:not(:checked) + label {
    background: var(--bs-light);
}

#formatRadios input:checked + label {
    background: var(--bs-secondary);
    color: var(--bs-light);
}

#imagesChecks input + label img,
#cornerRadios input + label img,
#coverRadios input + label img {
    object-fit: cover;
    aspect-ratio: 1 / 1;
    pointer-events: none;
    user-select: none;
    transition: opacity ease 0.5s;
}

#imagesChecks input + label,
#cornerRadios input + label,
#coverRadios input + label {
    border: 2px solid var(--bs-light);
    border-radius: 0.375rem;
    transition: border-color ease 0.2s;
    cursor: pointer;
}

#imagesChecks input:checked + label,
#cornerRadios input:checked + label,
#coverRadios input:checked + label {
    border-color: var(--bs-primary);
}

#preview {
    width: 100%;
    height: 100%;
}
</style>