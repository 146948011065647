import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "@/views/HomeView";
import AdminUsersManage from "@/views/admin/AdminUsersManage";
import ProfileView from "@/views/ProfileView";
import ComposeView from "@/views/ComposeView";
import VienneseView from "@/views/VienneseView";
import AdminProductsManage from "@/views/admin/AdminProductsManage";

export default createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            meta: {
                title: 'Список меню',
                layout: 'MainLayout'
            },
            component: HomeView
        },
        {
            path: '/compose/:id?',
            meta: {
                title: 'Создание меню',
                layout: 'MainLayout'
            },
            component: ComposeView
        },
        {
            path: '/viennese',
            meta: {
                title: 'Венское меню',
                layout: 'MainLayout'
            },
            component: VienneseView
        },
        {
            path: '/profile',
            meta: {
                title: 'Профиль',
                layout: 'CupLayout'
            },
            component: ProfileView
        },
        {
            path: '/admin/users',
            meta: {
                title: 'Пользователи',
                layout: 'MainLayout'
            },
            component: AdminUsersManage
        },
        {
            path: '/admin/products',
            meta: {
                title: 'Товары',
                layout: 'MainLayout'
            },
            component: AdminProductsManage
        }
    ]
});
