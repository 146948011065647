<template>
    <main-header class="mb-4" />

    <div class="container">
        <slot />
    </div>
</template>

<script>
import MainHeader from "@/components/MainHeader";

export default {
    name: "MainLayout",
    components: {MainHeader}
}
</script>