<template>
    <bs-alert class="text-center my-3" ref="alert" />

    <div class="mb-3">
        <div class="form-label">Email <span class="text-primary">*</span></div>
        <input class="form-control" type="text" v-model.trim="email" />
    </div>

    <div class="mb-3">
        <div class="form-label">Пароль <span class="text-primary">*</span></div>
        <input class="form-control" type="password" v-model="password" />
    </div>

    <div class="mb-3">
        <div class="form-label">Каптча <span class="text-primary">*</span></div>
        <vue-recaptcha sitekey="6LeUdaUkAAAAABnNBbHjupF-WSmK4uHYERE_lDX8" ref="recaptcha" v-on:verify="this.token = $event" v-on:expire="this.token = null" />
    </div>

    <div class="text-center mb-3">
        <a href="#" v-on:click="$emit('recovery')">Восстановить пароль</a>
    </div>

    <div class="text-center mb-2">
        <button type="button" class="btn btn-primary col-4" v-bind:disabled="!token" v-on:click="onSubmit">Войти</button>
    </div>
</template>

<script>
import api from "@/api";
import BsAlert from "@/components/BsAlert";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: "SignInForm",
    components: {BsAlert, VueRecaptcha},
    data() {
        return {
            email: '',
            password: '',
            token: null
        }
    },
    methods: {
        async onSubmit() {
            return await api.profile.signIn(this.email, this.password, this.token)
                .then((response) => this.$refs.alert.set(response.cause, 'success'))
                .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
                .then(() => this.$store.dispatch('fetchProfile'))
                .catch((error) => this.$refs.alert.set(error, 'danger'));
        }
    }
}
</script>