<template>
    <teleport to="body">
        <div class="modal fade" tabindex="-1" ref="modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Удалить позицию?</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="bg-light rounded p-3">
                            <div class="text-primary fw-semibold lead">{{name}}</div>
                            <div class="d-flex">
                                <div class="me-2">{{volume}} мл</div>
                                <div class="me-2">{{price}} руб</div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
                        <button type="button" class="btn btn-primary" v-on:click="onSubmit"><i class="bi bi-x-circle"></i> Удалить</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    name: "DeleteProductModal",
    data() {
        return {
            name: '',
            description: '',
            volume: null,
            price: null,
            index: -1,
            categoryId: 0,

            bsModal: null
        }
    },
    methods: {
        show(product = {}, index = -1) {
            this.name = product.name || '';
            this.description = product.description || '';
            this.volume = product.volume || 250.0;
            this.price = product.price || 100.0;
            this.index = index;
            this.categoryId = product.category_id || 0;
            this.bsModal.show();
        },
        hide() {
            this.bsModal.hide();
        },
        onSubmit() {
            this.$emit('submit', {
                name: this.name,
                description: this.description,
                volume: this.volume,
                price: this.price,
                index: this.index,
                category_id: this.categoryId
            });

            this.bsModal.hide();
        }
    },
    mounted() {
        this.bsModal = Modal.getOrCreateInstance(this.$refs.modal);
    },
    unmounted() {
        this.bsModal.dispose();
    }
}
</script>