<template>
    <div class="card">
        <div class="card-header" v-if="!['thanks'].includes(tab)">
            <div class="nav nav-tabs nav-fill card-header-tabs">
                <button v-bind:class="{'nav-link': true, 'active': tab === 'sign_in'}" v-on:click="tab = 'sign_in'">Авторизация</button>
                <button v-bind:class="{'nav-link': true, 'active': tab === 'sign_up'}" v-on:click="tab = 'sign_up'">Регистрация</button>
            </div>
        </div>
        <div class="card-body px-4" v-if="tab === 'sign_in'">
            <sign-in-form v-on:recovery="tab = 'recovery_submit'" />
        </div>
        <div class="card-body" v-else-if="tab === 'sign_up'">
            <sign-up-form v-on:success="tab = 'thanks'" />
        </div>
        <div class="card-body" v-else-if="tab === 'recovery_submit'">
            <recovery-form />
        </div>
        <div class="card-body" v-else-if="tab === 'recovered'">
            <p class="text-center">Восстановление пароля</p>
            <p>{{recovery}}</p>

            <div class="text-center">
                <button class="btn btn-primary col-4" v-on:click="tab = 'sign_in'">Вход</button>
            </div>
        </div>
        <div class="card-body" v-else-if="tab === 'thanks'">
            <p class="text-center">Спасибо за регистрацию!</p>
            <p>После проверки указанных вами данных, мы пришлём ответ на ваш email!</p>

            <div class="text-center">
                <button class="btn btn-primary col-4" v-on:click="tab = 'sign_in'">Назад</button>
            </div>
        </div>
    </div>
</template>

<script>

import SignInForm from "@/components/forms/SignInForm";
import SignUpForm from "@/components/forms/SignUpForm";
import RecoveryForm from "@/components/forms/RecoveryForm";
import {useRoute} from "vue-router";
import api from "@/api";
export default {
    name: 'SignView',
    components: {RecoveryForm, SignUpForm, SignInForm},
    data() {
        return {
            tab: 'sign_in',
            recovery: ''
        }
    },
    mounted() {
        const route = useRoute();

        if (route.query.id !== undefined &&
            route.query.token !== undefined) {

            const id = route.query.id;
            const token = route.query.token;

            api.profile.recoveryConfirm(id, token)
                .then((response) => {
                    this.tab = 'recovered';

                    if (response.success) {
                        this.recovery = `Ваш новый пароль - ${response.data.password}`;
                    } else {
                        this.recovery = response.cause;
                    }
                });

        }
    }
}
</script>
