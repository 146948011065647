<template>
    <teleport to="body">
        <div class="modal fade" tabindex="-1" ref="modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Составление позиции</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <div class="form-label">Название <span class="text-primary">*</span></div>
                            <input type="text" class="form-control" placeholder="Введите название..." v-model.trim="name" />
                        </div>

                        <div class="mb-3">
                            <div class="form-label">Описание</div>
                            <textarea class="form-control" v-model="description"></textarea>
                        </div>

                        <div class="row row-cols-2 g-3 mb-3">
                            <div class="col">
                                <div class="form-label">Объем (мл) <span class="text-primary">*</span></div>
                                <input type="number" class="form-control" step="1" v-model.number="volume" />
                            </div>

                            <div class="col">
                                <div class="form-label">Цена (руб) <span class="text-primary">*</span></div>
                                <input type="number" class="form-control" step="0.01" v-model.number="price" />
                            </div>
                        </div>

                        <div class="mb-3">
                            <div class="form-label">Категория</div>
                            <select class="form-select" v-model="categoryId">
                                <option v-bind:value="null">Без категории</option>
                                <option
                                    v-for="[id, category] in Object.entries(categories)"
                                    v-bind:key="id"
                                    v-bind:value="id"
                                >{{category}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
                        <button type="button" class="btn btn-primary" v-on:click="onSubmit"><i class="bi bi-check-circle"></i> Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import {Modal} from "bootstrap";
import api from "@/api";

export default {
    name: "ProductComposeModal",
    data() {
        return {
            id: 0,
            name: '',
            description: '',
            volume: 250.0,
            price: 250.0,
            categoryId: null,
            categories: {},
            bsModal: null
        }
    },
    methods: {
        show(product = {}) {
            this.id = product.id || 0;
            this.name = product.name || '';
            this.description = product.description || '';
            this.volume = product.volume || 250.0;
            this.price = product.volume || 250.0;
            this.categoryId = product.category_id || null;
            this.bsModal.show();
        },
        onSubmit() {
            this.$emit('submit', {
                id: this.id,
                name: this.name,
                description: this.description,
                volume: this.volume,
                price: this.price,
                category_id: this.categoryId
            });

            this.bsModal.hide();
        }
    },
    mounted() {
        this.bsModal = Modal.getOrCreateInstance(this.$refs.modal);
        api.categories.browse().then((response) => this.categories = response.data);
    },
    unmounted() {
        this.bsModal.dispose();
    }
}
</script>

<style scoped>

</style>