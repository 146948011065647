<template>
    <div class="bg-white rounded p-4 my-auto col-12 col-lg-6 col-xl-4">
        <div class="mb-3">
            <div class="form-label fw-semibold lead">Имя клиента:</div>
            <input type="text" class="form-control" v-bind:value="$store.state.name" readonly />
        </div>

        <div class="mb-3">
            <div class="form-label fw-semibold lead">Email:</div>
            <input type="text" class="form-control" v-bind:value="$store.state.email" readonly />
        </div>

        <div class="mb-3">
            <div class="form-label fw-semibold lead">Юридическое лицо:</div>
            <input type="text" class="form-control" v-bind:value="$store.state.person" readonly />
        </div>

        <div class="mb-3">
            <div class="form-label fw-semibold lead">Регион:</div>
            <input type="text" class="form-control" v-bind:value="$store.state.region" readonly />
        </div>

        <div class="text-center">
            <button type="button" class="btn btn-outline-primary col-4" v-on:click="$router.push('/')">Назад</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProfileView"
}
</script>