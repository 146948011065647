<template>
    <teleport to="body">
        <div class="modal fade" tabindex="-1" ref="modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Удалить меню?</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        Все созданные данные будут удалены и прогресс будет потерян.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
                        <button type="button" class="btn btn-primary" v-on:click="onSubmit"><i class="bi bi-x-circle"></i> Удалить</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    name: "DeleteMenuModal",
    data() {
        return {
            bsModal: null
        }
    },
    methods: {
        show() {
            this.bsModal.show();
        },
        hide() {
            this.bsModal.hide();
        },
        onSubmit() {
            this.$emit('submit');
            this.bsModal.hide();
        }
    },
    mounted() {
        this.bsModal = Modal.getOrCreateInstance(this.$refs.modal);
    },
    unmounted() {
        this.bsModal.dispose();
    }
}
</script>