import axios from "axios";

const JMApi = {
    /**
     * @param {String} path
     * @param {Object|Array} data
     * @param {Object} options
     * @returns {Promise<{success: Boolean, cause: String, data: any}>}
     */
    request: async function(path, data = {}) {
        const config = {
            timeout: 5000,
            validateStatus: null,
            responseType: 'json'
        };

        const response = await axios.post(path, new URLSearchParams(data), config);

        if (response.status !== 200) throw response.data.cause || 'Неизвестная ошибка!';

        return {success: true, cause: response.data.cause, data: response.data.data};
    },
    profile: {
        /**
         * @returns {Promise<{id: Number, name: String, email: String, tin: Number}|null>}
         */
        profile: async function() {
            const response = await JMApi.request('/api/profile/view');

            if (response.success && response.data) {
                return {
                    id: response.data.id,
                    role: response.data.role,
                    name: response.data.name,
                    email: response.data.email,
                    person: response.data.person,
                    region: response.data.region,
                    responsibles: response.data.responsibles
                }
            }

            return null;
        },

        signIn: async function(email, password, token = '') {
            return await JMApi.request('/api/sign_in', {email, password, token});
        },

        signUp: async function(name, email, person, region_id, token = '') {
            return await JMApi.request('/api/sign_up', {name, email, person, region_id, token});
        },

        recoverySubmit: async function(email, token) {
            return await JMApi.request('/api/recovery/submit', {email, token});
        },

        recoveryConfirm: async function(id, token) {
            return await JMApi.request('/api/recovery/confirm', {id, token});
        },

        logout: async function() {
            await JMApi.request('/api/logout');
        }
    },
    account: {
        browse: async function(options = {page: 1, limit: 10}) {
            return await JMApi.request('/api/accounts/browse', options);
        },
        approve: async function(id) {
            return await JMApi.request('/api/accounts/approve', {id});
        },
        delete: async function(id) {
            return await JMApi.request('/api/accounts/delete', {id});
        }
    },
    categories: {
        browse: async function() {
            return await JMApi.request('/api/categories/browse');
        }
    },
    products: {
        browse: async function(page = 1, limit = 10, filters = {}) {
            const params = new URLSearchParams({page, limit, ...filters});
            return await JMApi.request(`/api/products/browse?${params.toString()}`);
        },
        delete: async function(id) {
            const response = await JMApi.request(`/api/products/delete?id=${id}`);
            return response.success;
        },
        compose: async function(values, id = null) {
            const response = await JMApi.request(`/api/products/compose?id=${id}`, values);
            return response.success;
        }
    },
    formats: {
        browse: async function() {
            return await JMApi.request('/api/formats/browse');
        }
    },
    regions: {
        browse: async function() {
            return await JMApi.request('/api/regions/browse');
        }
    },
    menu: {
        /**
         * @param {Number} id
         * @param {String} name
         * @param {String} title
         * @param {Boolean} isDraft
         * @param {Number} format
         * @param {Number} cover
         * @param {Number} corner
         * @param {Number[]} images
         * @param {URLSearchParams} encoded
         * @returns {Promise<{success: Boolean, cause: String, data: *}>}
         */
        save: async function(id, isDraft, name, title, format, cover, corner, images, encoded) {
            encoded.set('id', id.toString());
            encoded.set('name', name.trim());
            encoded.set('title', title.trim());
            encoded.set('draft', isDraft ? 'y' : 'n');
            encoded.set('format', format.toString());
            encoded.set('cover', cover.toString());
            encoded.set('corner', corner.toString());
            encoded.set('images', images.join(','));
            return await JMApi.request('/api/menu/save', encoded);
        },

        load: async function(id) {
            return await JMApi.request('/api/menu/load', {id});
        },

        browse: async function(page = 1, limit = 10) {
            return await JMApi.request('/api/menu/browse', {page, limit});
        },

        delete: async function(id) {
            return await JMApi.request('/api/menu/delete', {id});
        },

        rename: async function(id, name) {
            return await JMApi.request('/api/menu/rename', {id, name});
        },

        /**
         * @param {Number} formatId
         * @param {String} title
         * @param {Number} corner
         * @param {Number} cover
         * @param {Array} images
         * @param {Number} page
         * @param {Object.<any,{name: String, description: String, volume: Number, price: Number}[]>} list
         * @returns {Promise<{success: Boolean, cause: String, data: *}>}
         */
        preview: async function(formatId, title, corner, cover, images, page, list = {}) {
            const encoded = new URLSearchParams();

            encoded.set('format', formatId.toString());
            encoded.set('corner', corner.toString());
            encoded.set('cover', cover.toString());
            encoded.set('images', images.join(','));
            encoded.set('title', title.trim());
            encoded.set('page', page.toString());

            for (const [category, products] of Object.entries(list)) {
                let index = 0;
                for (const product of products) {
                    encoded.set(`menu[${category}][${index}][name]`, product.name);
                    encoded.set(`menu[${category}][${index}][description]`, product.description);
                    encoded.set(`menu[${category}][${index}][volume]`, product.volume.toString());
                    encoded.set(`menu[${category}][${index}][price]`, product.price.toString());
                    index++;
                }
            }

            const response = await axios.post('/api/menu/preview', encoded, {
                validateStatus: null,
                responseType: 'text'
            });

            if (response.status !== 200) throw response.data;

            return response.data;
        },

        buildById: async function(id) {
            const response = await axios.get(`/api/menu/build?menu_id=${id}`, {responseType: 'blob'});
            return response.data;
        },

        buildByData: async function (name, format, cover, corner, images, encoded) {
            encoded.set('title', name.trim());
            encoded.set('format', format.toString());
            encoded.set('cover', cover.toString());
            encoded.set('corner', corner.toString());
            encoded.set('images', images.join(','));

            const response = await axios.post(`/api/menu/build`, encoded, {responseType: 'blob'});

            return response.data;
        }
    }
}

export default JMApi;