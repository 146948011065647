<template>
    <teleport to="body">
        <div class="modal fade" tabindex="-1" ref="modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Составление позиции</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <div class="form-label">Название <span class="text-primary">*</span></div>
                            <input type="text" class="form-control" placeholder="Введите название..." v-model.trim="name" disabled />
                        </div>

                        <div class="row row-cols-2 g-3 mb-3">
                            <div class="col">
                                <div class="form-label">Объем (мл) <span class="text-primary">*</span></div>
                                <input type="number" class="form-control" step="1" v-model.number="volume" disabled />
                            </div>

                            <div class="col">
                                <div class="form-label">Цена (руб) <span class="text-primary">*</span></div>
                                <input type="number" class="form-control" step="0.01" v-model.number="price" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
                        <button type="button" class="btn btn-primary" v-on:click="onSubmit"><i class="bi bi-check-circle"></i> Применить</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    name: "ProductPriceModal",
    data() {
        return {
            id: 0,
            name: '',
            description: '',
            volume: null,
            price: null,
            index: -1,
            bsModal: null
        }
    },
    methods: {
        show(product = {}, index = -1) {
            this.id = product.id || 0;
            this.name = product.name || '';
            this.description = product.description || '';
            this.volume = product.volume || 250.0;
            this.price = product.price || 100.0;
            this.categoryId = product.category_id || 1;
            this.index = index;
            this.bsModal.show();
        },
        hide() {
            this.bsModal.hide();
        },
        onSubmit() {
            this.$emit('submit', {
                name: this.name,
                description: this.description,
                volume: this.volume,
                price: this.price,
                index: this.index
            });

            this.bsModal.hide();
        }
    },
    mounted() {
        this.bsModal = Modal.getOrCreateInstance(this.$refs.modal);
    },
    unmounted() {
        this.bsModal.dispose();
    }
}
</script>

<style scoped>

</style>