<template>
    <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
            <router-link class="nav-link" active-class="active" to="/admin/users">Пользователи</router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" active-class="active" to="/admin/products">Товары</router-link>
        </li>
    </ul>

    <div class="d-lg-flex justify-content-between">
        <div class="row row-cols-auto g-2 mb-3">
            <div class="col">
                <input type="text" class="form-control" placeholder="По названию..." v-model.trim="filters.by_name" />
            </div>
            <div class="col">
                <select class="form-select" v-model="filters.by_category">
                    <option value="">По категории...</option>
                    <option v-for="[id, category] in Object.entries(categories)" v-bind:key="id" v-bind:value="id">{{category}}</option>
                </select>
            </div>
            <div class="col">
                <button class="btn btn-primary" v-on:click="fetchProducts"><i class="bi bi-search"></i></button>
            </div>
        </div>
        <div class="mb-3">
            <button type="button" class="btn btn-primary" v-on:click.left="$refs.productComposeModal.show()" v-bind:disabled="$store.state.role !== 4"><i class="bi bi-plus-circle"></i> Новый товар</button>
        </div>
    </div>

    <table class="table">
        <thead>
            <tr>
                <th width="80px">ID</th>
                <th width="*">Название</th>
                <th width="100px">Объем</th>
                <th width="100px">Цена</th>
                <th width="150px">Категория</th>
                <th width="80px"></th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="product in products" v-bind:key="product.id">
                <td>{{product.id}}</td>
                <td>
                    <div class="">{{product.name}}</div>
                    <div class="text-muted small">{{product.description}}</div>
                </td>
                <td>{{product.volume}} <span class="text-muted">мл.</span></td>
                <td>{{product.price}} <span class="text-muted">₽</span></td>
                <td>{{product.category_id ? categories[product.category_id] : 'Без категории'}}</td>
                <td>
                    <div class="hstack gap-1" v-if="$store.state.role === 4">
                        <a href="#" class="d-block link-danger" v-on:click.left="deleteProduct(product.id)"><i class="bi bi-x-circle"></i></a>
                        <a href="#" class="d-block link-primary" v-on:click.left="$refs.productComposeModal.show(product)"><i class="bi bi-pencil-square"></i></a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <bs-pagination
        v-bind:current="page"
        v-bind:total="totalPages"
        v-bind:entries="total"
        v-bind:limit="limit"
        v-on:setPage="setPage"
        v-on:setLimit="setLimit"
    />

    <product-compose-modal ref="productComposeModal" v-on:submit="composeProducts($event, $event.id ? $event.id : null)" />
</template>

<script>
import api from "@/api";
import BsPagination from "@/components/BsPagination";
import ProductComposeModal from "@/components/modals/ProductComposeModal";

export default {
    name: "AdminProductsManage",
    components: {ProductComposeModal, BsPagination},
    data() {
        return {
            categories: {},
            products: [],
            page: 1,
            limit: 10,
            total: 0,

            filters: {
                by_name: '',
                by_category: ''
            }
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.total / this.limit);
        }
    },
    methods: {
        async fetchCategories() {
            const response = await api.categories.browse();
            this.categories = response.data;
        },
        async fetchProducts() {
            const response = await api.products.browse(this.page, this.limit, this.filters);

            this.products = response.data.rows;
            this.total = response.data.total;
        },
        async deleteProduct(id) {
            await api.products.delete(id);
            await this.fetchProducts();
        },
        async composeProducts(values, id = null) {
            await api.products.compose(values, id);
            await this.fetchProducts();
        },
        setPage(page) {
            this.page = page;
            this.fetchProducts();
        },
        setLimit(limit) {
            this.limit = limit;
            this.fetchProducts();
        }
    },
    mounted() {
        this.fetchCategories();
        this.fetchProducts();
    }
}
</script>

<style scoped>

</style>