<template>
    <div class="d-flex w-100 h-100" style="background: #f5f5f5">
        <img class="position-absolute m-4" v-bind:src="require('@/assets/images/logo.svg')" alt="JM Logo" width="92" height="92" />
        <div class="mx-auto my-auto" style="width: 100%; max-width: 25rem"><slot/></div>
    </div>
</template>

<script>
export default {
    name: "LockScreenLayout"
}
</script>