import {createStore} from 'vuex'
import api from "@/api";

export default createStore({
    state: {
        id: 0,
        role: 0,
        name: '',
        email: '',
        person: '',
        region: '',
        responsibles: []
    },
    getters: {
        isAuthed(state) {
            return state.id > 0;
        }
    },
    mutations: {
        update(state, profile) {
            state.id = profile.id;
            state.role = profile.role;
            state.name = profile.name;
            state.email = profile.email;
            state.person = profile.person;
            state.region = profile.region;
            state.responsibles = profile.responsibles;
        }
    },
    actions: {
        async fetchProfile(context) {
            const response = (await api.profile.profile()) || {};

            context.commit('update', {
                id: response.id || 0,
                role: response.role || 0,
                name: response.name || '',
                email: response.email || '',
                person: response.person || '',
                region: response.region || '',
                responsibles: response.responsibles || []
            });
        }
    },
    modules: {}
})
