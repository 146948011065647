<template>
    <div class="d-sm-flex align-items-center">
        <div class="me-3">
            <ul class="pagination mb-0">
                <li class="page-item">
                    <a class="page-link" href="javascript:" v-on:click="$emit('setPage', 1)"><i class="bi bi-chevron-double-left"></i></a>
                </li>
                <template v-for="page in total" v-bind:key="page">
                    <li v-if="page > current - 4 && page < current + 4"
                        class="page-item"
                        v-bind:class="{active: page === current}">
                        <a class="page-link" href="javascript:" v-on:click="$emit('setPage', page)">{{ page }}</a>
                    </li>
                </template>
                <li class="page-item">
                    <a class="page-link" href="javascript:" v-on:click="$emit('setPage', total)"><i class="bi bi-chevron-double-right"></i></a>
                </li>
            </ul>
        </div>
        <div v-if="entries > 0">
            Всего {{ entries }}. Стр. {{ current }} из {{ total }}
        </div>
        <div class="ms-sm-auto">
            <select class="form-select" v-on:input="$emit('setLimit', parseInt($event.target.value))">
                <option
                    v-for="i in limits"
                    v-bind:key="i"
                    v-bind:value="i"
                    v-bind:selected="i === limit"
                >{{ i }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "BsPagination",
    props: {
        current: {
            type: Number,
            default: 1
        },
        entries: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 0
        },
        limits: {
            type: Array,
            default: () => [10, 20, 50, 100]
        }
    }
}
</script>