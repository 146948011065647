<template>
    <teleport to="body">
        <div class="modal fade" tabindex="-1" ref="modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Сохранить черновик?</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-label">Перед сохранение введите название меню</div>
                        <input type="text" class="form-control" v-model.trim="name" />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
                        <button type="button" class="btn btn-primary" v-on:click="onSubmit"><i class="bi bi-check-circle"></i> Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    name: "SaveDraftModal",
    data() {
        return {
            bsModal: null,
            name: ''
        }
    },
    methods: {
        show() {
            this.bsModal.show();
        },
        onSubmit() {
            this.$emit('submit', this.name);
            this.bsModal.hide();
        }
    },
    mounted() {
        this.bsModal = Modal.getOrCreateInstance(this.$refs.modal);
    },
    unmounted() {
        this.bsModal.dispose();
    }
}
</script>