<template>
    <main-header />

    <div id="bg-coffee-cup" class="h-100 pb-5">
        <div class="d-flex container h-100">
            <slot />
        </div>
    </div>
</template>

<script>
import MainHeader from "@/components/MainHeader";
export default {
    name: "CupLayout",
    components: {MainHeader}
}
</script>

<style>
#bg-coffee-cup {
    background-image: url(@/assets/images/bg-coffee-cup.png);
    background-position: center;
    background-size: cover;
}
</style>