<template>
    <header class="navbar navbar-expand-md" style="background: #efefef">
        <div class="container">
            <a class="d-flex align-items-center navbar-brand" href="#">
                <img id="logo" class="d-block me-3" v-bind:src="require('@/assets/images/logo.svg')" alt="JM Logo" />
                <h2 class="fw-bold mb-0">{{$route.meta.title}}</h2>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item" v-if="[3, 4].includes($store.state.role) && $route.path !== '/admin/users'">
                        <a class="nav-link fw-normal px-3" href="javascript:" v-on:click="this.$router.push('/admin/users')">Управление</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link fw-normal px-3" href="javascript:" v-on:click="this.$router.push('/')">Главная</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link fw-normal px-3" href="javascript:" v-on:click="this.$router.push('/profile')">Профиль</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link fw-normal px-3" href="javascript:" v-on:click="logout()">Выход</a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
import api from "@/api";

export default {
    name: "MainHeader",
    methods: {
        logout() {
            api.profile.logout().then(() => this.$store.dispatch('fetchProfile'));
        }
    }
}
</script>

<style>
#logo {
    width: calc(1.8rem + 3vw);
    height: calc(1.8rem + 3vw);
}
</style>