<template>
    <div class="nav nav-tabs nav-fill d-none mb-3">
        <div v-bind:class="['nav-link', step === 1 ? 'active' : 'disabled']">ШАГ 1 Наполнение меню</div>
        <div v-bind:class="['nav-link', step === 2 ? 'active' : 'disabled']">ШАГ 2 Выбор формата-носителя</div>
    </div>

    <div class="row row-cols-1 row-cols-xl-2 mb-3" style="height: 40rem">
        <div class="col">
            <div class="row row-cols-1 row-cols-lg-2 g-1 mb-2">
                <div class="col">Формат:</div>
                <div class="col">
                    <div class="btn-group btn-group-sm w-100">
                        <input type="radio" class="btn-check" id="format0" name="format" value="0" v-model.number="format" />
                        <label class="btn btn-outline-primary w-50" for="format0">Одностраничный</label>
                        <input type="radio" class="btn-check" id="format1" name="format" value="1" v-model.number="format" />
                        <label class="btn btn-outline-primary w-50" for="format1">Двухстраничный</label>
                    </div>
                </div>
                <div class="col">Язык:</div>
                <div class="col">
                    <div class="btn-group btn-group-sm w-100">
                        <input type="radio" class="btn-check" id="locale0" name="locale" value="0" v-model.number="locale" />
                        <label class="btn btn-outline-primary w-50" for="locale0">РУС</label>
                        <input type="radio" class="btn-check" id="locale1" name="locale" value="1" v-model.number="locale" />
                        <label class="btn btn-outline-primary w-50" for="locale1">РУС + АНГЛ</label>
                    </div>
                </div>
                <div class="col">Описание:</div>
                <div class="col">
                    <div class="btn-group btn-group-sm w-100">
                        <input type="radio" class="btn-check" id="texts0" name="texts" value="0" v-model.number="texts" />
                        <label class="btn btn-outline-primary w-50" for="texts0">Включить</label>
                        <input type="radio" class="btn-check" id="texts1" name="texts" value="1" v-model.number="texts" />
                        <label class="btn btn-outline-primary w-50" for="texts1">Выключить</label>
                    </div>
                </div>
            </div>

            <div class="d-flex bg-light rounded px-3 py-2 mb-1" v-for="(product, index) in products" v-bind:key="product.id">
                <div class="flex-fill fw-semibold me-2">{{product.name}}</div>
                <div class="fw-light me-2">{{product.volume}} мл</div>
                <div class="me-2">{{product.price}} руб</div>
                <div><a href="javascript:" v-on:click="this.$refs.productPriceModal.show(product, index)"><i class="bi bi-pencil-square"></i></a></div>
            </div>
        </div>

        <div class="col">
            <div class="bg-light rounded p-3 h-100">
                <iframe id="preview" class="w-100 h-100" v-bind:srcdoc="preview"></iframe>
            </div>
        </div>
    </div>

    <div class="text-end mb-3">
        <button type="button" class="btn btn-primary mx-1" v-on:click="$router.push('/')"><i class="bi bi-arrow-left"></i> Назад</button>
        <button type="button" class="btn btn-primary mx-1" v-on:click="downloadMenu()"><i class="bi bi-check-circle"></i> Скачать меню</button>
    </div>

    <product-price-modal ref="productPriceModal" v-on:submit="onProductCompose" />
</template>

<script>
import api from "@/api";
import ProductPriceModal from "@/components/modals/ProductPriceModal";

export default {
    name: "VienneseView",
    components: {ProductPriceModal},
    methods: {
        async updatePreview() {
            await api.menu.preview(this.formatId, '', 1, 1, [], 2, {1: this.products})
                .then((response) => this.preview = response)
                .catch((error) => this.preview = error.toString());
        },
        async downloadMenu() {
            const encoded = new URLSearchParams();

            this.products.forEach((product, index) => {
                encoded.set(`menu[1][${index}][name]`, product.name);
                encoded.set(`menu[1][${index}][description]`, product.description);
                encoded.set(`menu[1][${index}][volume]`, product.volume);
                encoded.set(`menu[1][${index}][price]`, product.price);
            })

            const file = await api.menu.buildByData('', this.formatId, 1, 1, [], encoded);
            const anchor = document.createElement('a');
            const url = URL.createObjectURL(file);

            anchor.href = url;
            anchor.download = `Viennese.pdf`;
            anchor.click();

            URL.revokeObjectURL(url)
        },
        onProductCompose(product) {
            const index = product.index;

            this.products[index].name = product.name;
            this.products[index].description = product.description;
            this.products[index].volume = product.volume;
            this.products[index].price = product.price;

            this.updatePreview();
        }
    },
    data() {
        return {
            step: 1,
            page: 1,
            preview: '',
            format: 0,
            locale: 0,
            texts: 0,
            products: [
                {id: 1, name: 'Францисканер', description: '', volume: 180, price: 250},
                {id: 2, name: 'Айскофе по-венски', description: '', volume: 300, price: 250},
                {id: 3, name: 'Обермайер', description: '', volume: 150, price: 250},
                {id: 4, name: 'Мария Терезия', description: '', volume: 150, price: 250},
                {id: 5, name: 'Венский Меланж', description: '', volume: 140, price: 250},
                {id: 6, name: 'Браунер', description: '', volume: 50, price: 250},
                {id: 7, name: 'Двойной Браунер', description: '', volume: 100, price: 250},
                {id: 8, name: 'Ноймани', description: '', volume: 80, price: 250},
                {id: 9, name: 'Моцарт', description: '', volume: 150, price: 250},
                {id: 10, name: 'Шварцер', description: '', volume: 30, price: 250},
                {id: 11, name: 'Двойной Шварцер', description: '', volume: 60, price: 250}
            ]
        }
    },
    computed: {
        formatId() {
            return 8 + parseInt(this.format) + (parseInt(this.locale) * 2) + (parseInt(this.texts) * 4);
        }
    },
    watch: {
        formatId() {
            this.updatePreview();
        }
    },
    mounted() {
        this.updatePreview();
    }
}
</script>