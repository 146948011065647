<template>
    <template v-if="state === 0">
        <div class="position-absolute w-100 h-100" style="background: #f0f0f0"></div>
    </template>

    <template v-else-if="this.$store.getters.isAuthed">
        <component v-bind:is="layout" v-if="state === 1">
            <router-view/>
        </component>
    </template>
    <template v-else>
        <sign-layout>
            <sign-view />
        </sign-layout>
    </template>

    <transition v-bind:duration="{enter: 500, leave: 500}">
        <div class="d-flex bg-light justify-content-center align-items-center position-absolute placeholder-wave w-100 h-100" style="z-index: 999999" v-if="state === 0">
            <div class="placeholder bg-light w-100 h-100 position-absolute"></div>
            <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </transition>
</template>

<script>
import SignLayout from "@/views/layout/SignLayout";
import MainLayout from "@/views/layout/MainLayout";
import CupLayout from "@/views/layout/CupLayout";
import SignView from "@/views/SignView";

export default {
    components: {
        SignView,
        SignLayout,
        MainLayout,
        CupLayout
    },
    data() {
        return {
            state: 0
        }
    },
    mounted() {
        this.$store.dispatch('fetchProfile')
            .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
            .then(() => this.state = 1)
            .catch(() => this.state = 2);
    },
    computed: {
        layout() {
            return this.$route.meta.layout || 'defaultLayout';
        }
    }
}
</script>

<style>
body {
    font-family: 'TT-Prosto', sans-serif !important;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease-in 0s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>