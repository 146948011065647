<template>
    <div class="row row-cols-1 row-cols-lg-2 g-4 mb-4">
        <div class="col">
            <div class="d-flex flex-column justify-content-end rounded bg-card bg-card-menu p-4 h-100">
                <button type="button" class="btn btn-outline-dark" v-on:click="$router.push('/compose')">Создать</button>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column justify-content-end rounded bg-card bg-card-cocktail p-4 h-100">
                <button type="button" class="btn btn-outline-dark" v-on:click="$router.push('/viennese')">Загрузить меню</button>
            </div>
        </div>
    </div>

    <table class="table table-hover">
        <thead>
        <tr>
            <th>Название</th>
            <th>Дата</th>
            <th>Формат-носитель</th>
            <th>Действия</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="menu in list" v-bind:key="menu.id">
            <td><router-link v-bind:to="`/compose/${menu.id}`" class="link-secondary">№{{menu.id}} — {{menu.name}}</router-link></td>
            <td>{{menu.created_at}}</td>
            <td>{{menu.format_name || 'Не задан.'}}</td>
            <td>
                <div class="dropdown">
                    <a href="#" type="button" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu">
                        <li><router-link class="dropdown-item" v-bind:to="`/compose/${menu.id}`">Редактировать</router-link></li>
                        <li><a class="dropdown-item" href="#" v-on:click="renameMenu(menu.id)">Переименовать</a></li>
                        <li><a class="dropdown-item" v-bind:href="`/api/menu/build?menu_id=${menu.id}`" target="_blank">Просмотр</a></li>
                        <li><a class="dropdown-item" v-bind:href="`/api/menu/build?menu_id=${menu.id}`" download>Скачать</a></li>
                        <li><hr class="dropdown-divider" /></li>
                        <li><a class="dropdown-item link-primary" href="#" v-on:click="deleteMenu(menu.id)">Удалить меню</a></li>
                    </ul>
                </div>
            </td>
        </tr>
        </tbody>
    </table>

    <bs-pagination
        v-bind:current="currentPage"
        v-bind:total="totalPages"
        v-bind:entries="totalItems"
        v-on:setLimit="setLimit"
        v-on:setPage="setPage"
    />

    <delete-menu-modal
        v-on:submit="onDeleteMenu"
        ref="deleteMenuModal"
    />

    <rename-menu-modal
        v-on:submit="onRenameMenu"
        ref="renameMenuModal"
    />
</template>

<script>
import api from "@/api";
import BsPagination from "@/components/BsPagination";
import DeleteMenuModal from "@/components/modals/DeleteMenuModal";
import RenameMenuModal from "@/components/modals/RenameMenuModal";

export default {
    name: "HomeView",
    components: {RenameMenuModal, DeleteMenuModal, BsPagination},
    data() {
        return {
            list: [],
            formats: [],
            currentPage: 1,
            totalItems: 0,
            limit: 10,
            menuId: 0
        }
    },
    methods: {
        async fetchList() {
            const response = await api.menu.browse(this.currentPage, this.limit);
            this.list = response.data.rows;
            this.totalItems = response.data.total;
        },
        async fetchFormats() {
            return 1;
        },
        setLimit(limit) {
            this.limit = limit;
            this.fetchList();
        },
        setPage(page) {
            this.currentPage = page;
            this.fetchList();
        },
        renameMenu(id) {
            this.menuId = id;
            this.$refs.renameMenuModal.show();
        },
        deleteMenu(id) {
            this.menuId = id;
            this.$refs.deleteMenuModal.show();
        },
        async onDeleteMenu() {
            await api.menu.delete(this.menuId);
            await this.fetchList();
        },
        async onRenameMenu(name) {
            await api.menu.rename(this.menuId, name);
            await this.fetchList();
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.limit);
        }
    },
    mounted() {
        this.fetchList();
    }
}
</script>

<style scoped>
.bg-card {
    min-height: 10rem;
    background-size: cover;
    background-position-x: right;
    background-position-y: center;
}

.bg-card.bg-card-menu {
    background-image: url('@/assets/images/cards/bg-coffee-menu.png');
    background-position: left center;
}

.bg-card.bg-card-cocktail {
    background-image: url('@/assets/images/cards/bg-coffee-cocktail.png');
    background-position: left center;
}

.bg-card button {
    max-width: 10rem;
    width: 10rem;
}
</style>