<template>
    <bs-alert class="text-center my-3" ref="alert" />

    <div class="mb-3">
        <div class="form-label">Ваше имя <span class="text-primary">*</span></div>
        <input class="form-control" type="text" v-model.trim="name" />
    </div>

    <div class="mb-3">
        <div class="form-label">Email <span class="text-primary">*</span></div>
        <input class="form-control" type="email" v-model.trim="email" />
    </div>

    <div class="mb-3">
        <div class="form-label">Юридическое лицо <span class="text-primary">*</span></div>
        <input class="form-control" type="text" v-model.trim="person" />
    </div>

    <div class="mb-3">
        <div class="form-label">Регион <span class="text-primary">*</span></div>
        <select class="form-select" v-model.number="regionId">
            <optgroup v-for="[district, regions] of Object.entries(districts)" v-bind:key="district" v-bind:label="district">
                <option v-for="[id, name] of Object.entries(regions)" v-bind:key="id" v-bind:value="id">{{name}}</option>
            </optgroup>
        </select>
    </div>

    <div class="mb-3">
        <div class="form-label">Каптча <span class="text-primary">*</span></div>
        <vue-recaptcha sitekey="6LeUdaUkAAAAABnNBbHjupF-WSmK4uHYERE_lDX8" ref="recaptcha" v-on:verify="this.token = $event" v-on:expire="this.token = null" />
    </div>

    <div class="text-center mb-2">
        <button type="button" class="btn btn-primary col-4" v-bind:disabled="!token" v-on:click="onSubmit">Отправить</button>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import BsAlert from "@/components/BsAlert";
import api from "@/api";

export default {
    name: "SignUpForm",
    components: {
        VueRecaptcha,
        BsAlert
    },
    data() {
        return {
            name: '',
            email: '',
            person: '',
            regionId: null,
            token: '',
            districts: {}
        }
    },
    methods: {
        async fetchRegions() {
            const response = await api.regions.browse();

            for (const region of response.data) {
                if (this.districts[region.district] === undefined) {
                    this.districts[region.district] = {};
                }

                this.districts[region.district][region.id] = region.name;
            }
        },
        async onSubmit() {
            return await api.profile.signUp(this.name, this.email, this.person, this.regionId, this.token)
                .then((response) => this.$refs.alert.set(response.cause, 'success'))
                .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
                .then(() => this.$emit('success', true))
                .catch((error) => this.$refs.alert.set(error, 'danger'));
        }
    },
    mounted() {
        this.fetchRegions();
    }
}
</script>