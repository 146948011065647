<template>
    <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
            <router-link class="nav-link" active-class="active" to="/admin/users">Пользователи</router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" active-class="active" to="/admin/products">Товары</router-link>
        </li>
    </ul>

    <div class="row row-cols-auto mb-3 g-2">
        <div class="col">
            <div class="form-label">По роли</div>
            <select class="form-select" v-model.number="byRole">
                <option value="">Все</option>
                <option value="1">Ожидают</option>
                <option value="2">Пользователи</option>
                <option value="3">Менеджеры</option>
                <option value="4">Адмиистраторы</option>
            </select>
        </div>
        <div class="col">
            <div class="form-label">По имени</div>
            <input type="text" class="form-control" placeholder="Все" v-model.trim="byName" />
        </div>
        <div class="col">
            <div class="form-label">По email</div>
            <input type="text" class="form-control" placeholder="Все" v-model.trim="byEmail" />
        </div>
        <div class="col">
            <div class="form-label">По региону</div>
            <select class="form-select" v-model.number="byRegion" style="width: 15rem">
                <option v-bind:value="null">Не выбран</option>
                <template v-if="$store.state.role === 4">
                    <optgroup v-for="[district, regions] of Object.entries(districts)" v-bind:key="district" v-bind:label="district">
                        <option v-for="[id, name] of Object.entries(regions)" v-bind:key="id" v-bind:value="id">{{name}}</option>
                    </optgroup>
                </template>
                <template v-else>
                    <option
                        v-for="responsible in $store.state.responsibles"
                        v-bind:key="responsible"
                        v-bind:value="responsible"
                    >{{regions[responsible]}}</option>
                </template>
            </select>
        </div>
        <div class="col">
            <div class="form-label">&nbsp;</div>
            <button type="button" class="btn btn-primary" v-on:click="onSearch"><i class="bi bi-search"></i></button>
        </div>
    </div>

    <bs-alert class="p-2 mb-3" ref="alert" />

    <table v-bind:class="{'table': true, 'opacity-75': fething}">
        <thead>
            <tr>
                <th style="width: 4rem">ID</th>
                <th style="width: 10rem">Роль</th>
                <th style="width: 12rem">Регион</th>
                <th style="width: 12rem">Юридическое лицо</th>
                <th style="width: auto">Имя</th>
                <th style="width: 12rem">Email</th>
                <th style="width: 12rem">Регистрация</th>
                <th style="width: 4rem"><i class="bi bi-three-dots"></i></th>
            </tr>
        </thead>

        <tbody v-if="users.length > 0">
            <tr v-for="user in users" v-bind:key="user.id">
                <td>{{user.id}}</td>
                <td>
                    <span class="text-secondary" v-if="user.role === 1">Ожидает</span>
                    <span class="text-secondary" v-else-if="user.role === 2">Пользователь</span>
                    <span class="text-secondary" v-else-if="user.role === 3">Менеджер</span>
                    <span class="text-secondary" v-else-if="user.role === 4">Администратор</span>
                </td>
                <td>{{user.region}}</td>
                <td>{{user.person}}</td>
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.created_at}}</td>
                <td>
                    <a class="mx-1" href="javascript:" v-if="user.role === 1" v-on:click="approveUser(user.id)"><i class="bi bi-check-circle"></i></a>
                    <a class="mx-1" href="javascript:" v-if="user.role !== 3" v-on:click="deleteUser(user.id)"><i class="bi bi-x-circle"></i></a>
                </td>
            </tr>
        </tbody>
        <tbody v-else-if="fething">
            <tr>
                <td colspan="8" class="text-center text-secondary">Загрузка...</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="8" class="text-center text-secondary">Ничего не найдено :(</td>
            </tr>
        </tbody>
    </table>

    <bs-pagination
        v-bind:current="currentPage"
        v-bind:total="totalPages"
        v-bind:entries="totalItems"
        v-bind:limit="limit"
        v-on:setPage="setPage"
        v-on:setLimit="setLimit"
    />
</template>

<script>
import api from "@/api";
import BsPagination from "@/components/BsPagination";
import BsAlert from "@/components/BsAlert";

export default {
    name: "AdminUsersManage",
    components: {BsAlert, BsPagination},
    data() {
        return {
            users: [],
            fething: false,
            currentPage: 1,
            totalItems: 0,
            limit: 10,
            districts: {},
            regions: {},

            byRole: '',
            byName: '',
            byEmail: '',
            byRegion: null
        }
    },
    methods: {
        async fetchRegions() {
            const response = await api.regions.browse();

            for (const region of response.data) {
                if (this.districts[region.district] === undefined) {
                    this.districts[region.district] = {};
                }

                this.districts[region.district][region.id] = region.name;
                this.regions[region.id] = region.name;
            }
        },
        async fetchUsers() {
            if (this.fething) return;

            this.fething = true;
            await new Promise((resolve) => setTimeout(resolve, 300));
            const response = await api.account.browse({
                page: this.currentPage,
                limit: this.limit,
                by_role: this.byRole,
                by_name: this.byName,
                by_email: this.byEmail
            });

            this.users = response.data.rows;
            this.totalItems = response.data.total;
            this.fething = false;
        },

        async approveUser(id) {
            const response = await api.account.approve(id);
            this.$refs.alert.set(response.cause);
            await this.fetchUsers();
        },

        async deleteUser(id) {
            const response = await api.account.delete(id);
            this.$refs.alert.set(response.cause);
            await this.fetchUsers();
        },

        onSearch() {
            this.currentPage = 1;
            this.fetchUsers();
        },

        setPage(page) {
            this.currentPage = page;
            this.fetchUsers();
        },

        setLimit(limit) {
            this.limit = limit;
            this.fetchUsers();
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.limit);
        }
    },
    mounted() {
        if ([3, 4].includes(this.$store.state.role)) {
            this.fetchUsers();
            this.fetchRegions();
        } else {
            this.$router.push('/');
        }
    }
}
</script>