<template>
    <div class="text-primary fw-semibold" v-if="text !== ''">{{text}}</div>
</template>

<script>
export default {
    name: "BsAlert",
    data() {
        return {
            type: 'info',
            text: ''
        }
    },
    methods: {
        reset() {
            this.text = '';
            this.type = '';
        },
        set(text, type = 'primary') {
            this.text = text;
            this.type = type;
        }
    }
}
</script>