<template>
    <bs-alert class="text-center my-3" ref="alert" />

    <div class="mb-3">
        <div class="form-label">Email для восстановления<span class="text-primary">*</span></div>
        <input class="form-control" type="text" v-model.trim="email" />
    </div>

    <div class="mb-3">
        <div class="form-label">Каптча <span class="text-primary">*</span></div>
        <vue-recaptcha sitekey="6LeUdaUkAAAAABnNBbHjupF-WSmK4uHYERE_lDX8" ref="recaptcha" v-on:verify="this.token = $event" v-on:expire="this.token = null" />
    </div>

    <div class="text-center mb-2">
        <button type="button" class="btn btn-primary col-4" v-bind:disabled="!token" v-on:click="onSubmit">Войти</button>
    </div>
</template>

<script>
import BsAlert from "@/components/BsAlert";
import { VueRecaptcha } from 'vue-recaptcha';
import api from "@/api";

export default {
    name: "RecoveryForm",
    components: {BsAlert, VueRecaptcha},
    methods: {
        async onSubmit() {
            await api.profile.recoverySubmit(this.email, this.token)
                .then(response => this.$refs.alert.set(response.cause))
                .catch(error => this.$refs.alert.set(error.toString()));
        }
    },
    data() {
        return {
            token: '',
            email: ''
        }
    }
}
</script>

<style scoped>

</style>